import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const Warzenbehandlung = ({ data, location, ...props }) => {
    return (
        <Layout
            location={location}
            title="Warzenbehandlung"
            noSlider
            {...props}
        >
            <p>
                Es gibt die typischen Warzen und auch warzenähnliche
                Hautveränderungen an unseren Händen und Füßen. Fast immer sind
                es harmlose Hautveränderungen, ausgelöst durch bestimmte
                Virustypen. Besonders bei Kindern verschwinden diese Warzen nach
                Monaten von alleine. Von aggressiven Methoden der Entfernung
                raten nicht nur wir ab. Nach operativer Entfernung, besonders
                auch durch den allseits so beliebten Zauberstab LASER, entstehen
                Wunden, welche sich infizieren können, mitunter Narben, welche
                besonders im Bereich der Fußsohlen dauerhafte Beschwerden
                verursachen können. Die Rezidiv Quote nach operativer Entfernung
                liegt nach Literaturangaben bei bis zu 30%.
            </p>
            <p>
                Deshalb raten wir zu einem sinnvollen, feinfühligen Umgang mit
                diesen Quälgeistern. Es gibt diverse Behandlungsformen, die die
                Chancen erhöhen, die Warzen schneller los zu werden. Leider gibt
                es keine Behandlung, die immer und rasch hilft. Wir beobachten
                eine eingeleitete Behandlung über 4- 8 Wochen und schlagen bei
                Erfolglosigkeit weitere Therapien aus unserem Portfolio der
                Therapiemöglichkeiten vor und entscheiden mit Ihnen gemeinsam
                den weiteren Behandlungspfad.
            </p>
            <p>Sie brauchen in jedem Fall Geduld und Ausdauer!</p>

            <Questions title="Warzenbehandlung" />
        </Layout>
    );
};

export default Warzenbehandlung;
